import React from "react"


import plaiz from '../assets/plaiz.svg';

// import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"
//import rond_color from "../assets/rond_color.svg"

import "../styles/bootstrap.min.css"
import "./index.css"
import "./dashboard.css"
import Chart from "../components/chart.js"
import DonutChart from "react-svg-donut-chart"

const dataPie = [
    {value: 80, stroke: "#FB2682", strokeWidth: 5},   
    {value: 60, stroke: "#E142AE"},
    {value: 30, stroke: "#BB59E2"},
    {value: 20, stroke: "#8E5AF3"},
  ]

const Dashboard = () => (
  <div className="dashboard">
    <SEO title="Plaiz - Dashboard"/>
    <div className="window-size-div">
        <p className="window-size-text">Please increase your window size</p>
    </div>
    <img src={plaiz} className="logo-plaiz-dashboard"  alt="logo"/> 
    <div className="side-bar"/>
    <div className="graph-gallery-metrics-demographic">
        <div className="top-bar">
            <p className="search-text">
                Recherche
            </p>
        </div>
        <div className="graph-and-gallery">
            <p className="graph-title">
                Tendance sur la durée: Néon
            </p>
            <div className="graph-div">
                <Chart className="chart"/>
            </div>
            <p className="gallery-title">
                Inspiration Néon
            </p>
            <div className="gallery">
                <img className="gallery-image" src={"https://i.pinimg.com/originals/3d/96/8b/3d968b2d3fc8470822ebe9985e0cd6d9.jpg"}/>
                <img className="gallery-image" src={"https://www.lookdujour.ca/image/policy:1.8765625:1550150293/Couleurs-neons-tendance-mode-hommes-2019.jpg?$p=e744751"}/>
                <img className="gallery-image" src={"https://i.pinimg.com/originals/0c/fc/0d/0cfc0d2831b0535c84a6c206324cc2ec.jpg"}/>
                <img className="gallery-image" src={"https://data.whicdn.com/images/326406803/original.jpg"}/>
                <img className="gallery-image" src={"https://d2h1pu99sxkfvn.cloudfront.net/b0/13948234/525167978_XtLAWua3vs/P0.jpg"}/>
                <img className="gallery-image" src={"https://www.kissmebangbang.com/wp-content/uploads/2019/10/billie-eilish-inspired-neon-green-overalls-11.jpg"}/>
                <img className="gallery-image" src={"https://cdn.cliqueinc.com/posts/270366/neon-outfits-for-fall-270366-1539815321523-image.700x0c.png"}/>
                <img className="gallery-image" src={"https://i.pinimg.com/originals/26/1b/02/261b02fa47c7cc0a7cd2ce8d6c70e2ba.jpg"}/>
                <img className="gallery-image" src={"https://0.soompi.io/wp-content/uploads/2019/06/19225204/vernon.png"}/>
                <img className="gallery-image" src={"https://cf.shopee.com.my/file/dbe9e58b18baad29aacd9d1d3dbb7ac2"}/>
            </div>
        </div>
        <div className="metrics-and-demographics">
            <p className="metrics-title">
                Key metrics
            </p>
            <div className="metrics-div">
                <div className="demographic-param-div">
                    <p className="demographic-param-text">
                        De
                    </p>
                    <p className="demographic-param">
                        Juin ▽
                    </p>
                    <p className="demographic-param-text">
                        À
                    </p>
                    <p className="demographic-param">
                        Septembre ▽
                    </p>
                </div>
                <div className="metrics-list-item">
                    <p className="metrics-label">
                        Publications
                    </p>
                    <p className="metrics-variation">
                        +37%
                    </p>
                    <p className="metrics-value">
                        1 435
                    </p>
                </div>
                <div className="metrics-list-item">
                    <p className="metrics-label">
                        Portée
                    </p>
                    <p className="metrics-variation">
                        +14%
                    </p>
                    <p className="metrics-value">
                        43k
                    </p>
                </div>
                <div className="metrics-list-item">
                    <p className="metrics-label">
                        Clap
                    </p>
                    <p className="metrics-variation">
                        +16%
                    </p>
                    <p className="metrics-value">
                        1 039
                    </p>
                </div>
                <div className="metrics-list-item">
                    <p className="metrics-label">
                        Like
                    </p>
                    <p className="metrics-variation">
                        +39%
                    </p>
                    <p className="metrics-value">
                        10,6k
                    </p>
                </div>
                <div className="metrics-list-item" style={{borderBottomWidth:0}}>
                    <p className="metrics-label">
                        Superlike
                    </p>
                    <p className="metrics-variation">
                        +26%
                    </p>
                    <p className="metrics-value">
                        5,4k
                    </p>
                </div>
            </div>
            <p className="demographic-title">
                Données démographiques
            </p>
            <div style={{height:176, width:176, position:"absolute", borderRadius:100, top:435, left:807, opacity:0.7, boxShadow:"0px 0px 15px #FB2682", }}/>
            <div className="demographic-div">
                <div className="demographic-param-div">
                    <p className="demographic-param-text">
                        De
                    </p>
                    <p className="demographic-param">
                        Juin ▽
                    </p>
                    <p className="demographic-param-text">
                        À
                    </p>
                    <p className="demographic-param">
                        Septembre ▽
                    </p>
                </div>
                <div className="demographic-subdiv">
                    <DonutChart data={dataPie} className="donut-chart"/>
                    <p className="donut-center">
                        12 318<br/><span style={{fontSize:10, fontWeight:"normal"}}>Publications</span>
                    </p>
                    <div className="demographic-labels-and-colors">
                        <div className="label-and-color">
                            <div className="demo-color" style={{backgroundColor:"#FB2682"}}/>
                            <p className="demographic-label">
                                13 - 18 ans
                            </p>
                        </div>
                        <div className="label-and-color">
                            <div className="demo-color" style={{backgroundColor:"#E142AE"}}/>
                            <p className="demographic-label">
                                18 - 23 ans
                            </p>
                        </div>
                        <div className="label-and-color">
                            <div className="demo-color" style={{backgroundColor:"#BB59E2"}}/>
                            <p className="demographic-label">
                                23 - 28 ans
                            </p>
                        </div>
                        <div className="label-and-color">
                            <div className="demo-color" style={{backgroundColor:"#8E5AF3"}}/>
                            <p className="demographic-label">
                                28 et plus
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>

)

export default Dashboard