import React from 'react'
import { Chart } from 'react-charts'
  
const defs = (
    <defs>
      <linearGradient id="0" x1="0" x2="0" y1="1" y2="0">
        <stop offset="0%" stopColor="#C73989" />
        <stop offset="100%" stopColor="#8A56ED" />
      </linearGradient>
      <linearGradient id="1" x1="0" x2="0" y1="1" y2="0">
        <stop offset="0%" stopColor="#93F194" />
        <stop offset="100%" stopColor="#42D8CB" />
      </linearGradient>
    </defs>
  )
  export default function CustomStyles() {
    const [{ activeSeriesIndex, activeDatumIndex }, setState] = React.useState({
      activeSeriesIndex: -1,
      activeDatumIndex: -1
    })
    return (
      <div>
        <MyChart
          elementType="line"
          setState={setState}
          activeDatumIndex={activeDatumIndex}
          activeSeriesIndex={activeSeriesIndex}
          style={{fontColor:"white"}}
        />
      </div>
    )
  }
  function MyChart({
    elementType,
    activeDatumIndex,
    activeSeriesIndex,
    setState
  }) {
    const data = React.useMemo(
        () => [
            {
                label: 'Series 1',
                data: [[0, 1], [1, 2], [2, 4], [3, 2], [4, 7]]
            },
            {
                label: 'Series 2',
                data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]]
            }
        ],
        []
    )
    const series = React.useMemo(
      () => ({
        type: elementType
      }),
      [elementType]
    )
    const axes = React.useMemo(
        () => [
            {
          primary: true,
          type: 'ordinal',
          position: 'bottom',
            },
            {
          type: 'linear',
          position: 'left',
          stacked: true,
            },
        ],
        []
    )
    const getSeriesStyle = React.useCallback(
      series => ({
        color: `url(#${series.index % 4})`,
        opacity:
          activeSeriesIndex > -1
            ? series.index === activeSeriesIndex
              ? 1
              : 0.3
            : 1
      }),
      [activeSeriesIndex]
    )
    const getDatumStyle = React.useCallback(
      datum => ({
        r:
          activeDatumIndex === datum.index &&
          activeSeriesIndex === datum.seriesIndex
            ? 7
            : activeDatumIndex === datum.index
            ? 5
            : datum.series.index === activeSeriesIndex
            ? 3
            : datum.otherHovered
            ? 2
            : 2
        }),
      [activeDatumIndex, activeSeriesIndex]
    )
    const onFocus = React.useCallback(
      focused =>
        setState({
          activeSeriesIndex: focused ? focused.series.id : -1,
          activeDatumIndex: focused ? focused.index : -1
        }),
      [setState]
    )
    return (
        <div style={{width: 600, height: 240, padding:10}}>
        <Chart
            data={data}
            series={series}
            axes={axes}
            getSeriesStyle={getSeriesStyle}
            getDatumStyle={getDatumStyle}
            onFocus={onFocus}
            renderSVG={() => defs}
            tooltip
            dark
        />
        </div>
    )
}